import texts from '@components/Homepage/en.json';
import { CB_PRODUCT_ROUTE, GSEC_SDL_PRODUCT_ROUTE, SGB_PRODUCT_ROUTE, TB_PRODUCT_ROUTE } from '@ui/lib/constants/routes';

export const ourInvestors = [
    {
        image: '/images/homepage/investors/Siddharth Shah.png',
        name: 'Siddharth Shah',
        position: 'Co-founder, Pharmeasy',
        alt: 'siddharth shah cofounder of pharmeasy',
    },
    {
        image: '/images/homepage/investors/Mankekar.svg',
        name: 'Mankekar',
        position: 'Family Office',
        alt: 'Mankekar',
    },
    {
        image: '/images/homepage/investors/Capital A.svg',
        name: 'Capital - A',
        position: 'Venture Capital',
        alt: 'Capital - A',
    },
    {
        image: '/images/homepage/investors/Dharmil Sheth.png',
        name: 'Dharmil Sheth',
        position: 'Co-founder, Pharmeasy',
        alt: 'dharmil sheth cofounder of pharmeasy',
    },
    {
        image: '/images/homepage/investors/Ramakant Sharma.png',
        name: 'Ramakant Sharma',
        position: 'Co-founder, LivSpace',
        alt: 'ramakant sharma cofounder livspace',
    },
];

export const mediaCoverage = [
    {
        url: 'http://epaper.trinitymirror.net/Home/ShareArticle?OrgId=224a367888b&imageview=0',
        image: 'https://jiraaf-static-public-images.s3.ap-south-1.amazonaws.com/media-articles/Trinity.png',
        dWidth: '150',
        dHeight: '44',
        mWidth: '200',
        mHeight: '36',
        alt: 'trinitymirror site link icon',
    },
    {
        url: 'https://www.lokmattimes.com/business/fintech-platform-jiraaf-offers-fixed-income-options-to-investors-during-akshaya-tritiya-1/',
        image: 'https://jiraaf-static-public-images.s3.ap-south-1.amazonaws.com/media-articles/LokmatTimes.png',
        dWidth: '240',
        dHeight: '44',
        mWidth: '260',
        mHeight: '44',
        alt: 'lokmattimes site link icon',
    },
    {
        url: 'https://www.zee5.com/articles/fintech-platform-jiraaf-offers-fixed-income-options-to-investors-during-akshaya-tritiya',
        image: 'https://jiraaf-static-public-images.s3.ap-south-1.amazonaws.com/media-articles/Zee5.png',
        dWidth: '76',
        dHeight: '24',
        mWidth: '48',
        mHeight: '48',
        alt: 'zee5 site icon',
    },
    {
        url: 'https://www.business-standard.com/amp/content/press-releases-ani/fintech-platform-jiraaf-offers-fixed-income-options-to-investors-during-akshaya-tritiya-123042100841_1.html',
        image: 'https://jiraaf-static-public-images.s3.ap-south-1.amazonaws.com/media-articles/BusinessStandard.png',
        dWidth: '200',
        dHeight: '43',
        mWidth: '200',
        mHeight: '35',
        alt: 'business-standard site link icon',
    },
    {
        url: 'https://aninews.in/news/business/business/fintech-platform-jiraaf-offers-fixed-income-options-to-investors-during-akshaya-tritiya20230421144020/',
        image: 'https://jiraaf-static-public-images.s3.ap-south-1.amazonaws.com/media-articles/ANI_original.png',
        dWidth: '150',
        dHeight: '45',
        mWidth: '168',
        mHeight: '35',
        alt: 'aninews site icon',
    },
    {
        url: 'https://www.thehindubusinessline.com/portfolio/personal-finance/alternative-fixed-income-investments-risks-gains-and-why-invest-in-them/article66720337.ece',
        image: 'https://jiraaf-static-public-images.s3.ap-south-1.amazonaws.com/media-articles/businessline.svg',
        dWidth: '156',
        dHeight: '56',
        mWidth: '216',
        mHeight: '48',
        alt: 'the hindu business line site link',
    },
];

export const BannerData = [
    {
        header: texts?.Diversify,
        description: texts?.DiversifyDescription,
        image: '/images/homepage/Diversify.svg',
    },
    {
        header: texts?.Explore,
        description: texts?.ExploreDescription,
        image: '/images/homepage/explore.svg',
    },
    {
        header: texts?.Invest,
        description: texts?.InvestDescription,
        image: '/images/homepage/invest.svg',
    },
    {
        header: texts?.Maximise,
        description: texts?.MaximiseDescription,
        image: '/images/homepage/maximise.svg',
    },
];

export const WhyInvestData = [
    {
        text: texts?.FixedIncomeOpportunities,
        image: '/images/homepage/dots.svg',
        alt: 'dots'
    },
    {
        text: texts?.RelationshipManager,
        image: '/images/homepage/frame.svg',
        alt: 'frame'
    },
    {
        text: texts?.TeamOfExperts,
        image: '/images/homepage/grid.svg',
        alt: 'grid'
    },
    {
        text: texts?.AccessToInformation,
        image: '/images/homepage/pipes.svg',
        alt: 'pipes'
    },
];

export const ProductsData = [
    {
        image: '/images/homepage/cb.svg',
        header: texts?.ListedCorporateBonds,
        yieldValue: '8%-18%',
        cta: CB_PRODUCT_ROUTE,
    },
    {
        image: '/images/homepage/tb.svg',
        header: texts?.TreasuryBills,
        yieldValue: '6.5%-7.5%',
        cta: TB_PRODUCT_ROUTE,
    },
    {
        image: '/images/homepage/gsec.svg',
        header: texts?.Gsec,
        yieldValue: '7% and above',
        cta: GSEC_SDL_PRODUCT_ROUTE,
    },
    {
        image: '/images/homepage/sgb.svg',
        header: texts?.SovereignGoldBonds,
        yieldValue: '2.5% (Fixed) + Variable',
        cta: SGB_PRODUCT_ROUTE,
    }
];
export const InvestmentOptionsData = [
    {
        image: '/images/homepage/low-risk.svg',
        header: texts?.LowRisk,
        description: texts?.LowRiskDescription,
    },
    {
        image: '/images/homepage/passive-income.svg',
        header: texts?.SecondaryIncome,
        description: texts?.SecondaryIncomeDescription,
    },
    {
        image: '/images/products/secure.svg',
        header: texts?.HighPriorityClaims,
        description: texts?.HighPriorityClaimsDescription,
    },
    {
        image: '/images/products/bill-cross.svg',
        header: texts?.TaxOptimization,
        description: texts?.TaxOptimizationDescription,
    },
    {
        image: '/images/products/stability.svg',
        header: texts?.TenureOptions,
        description: texts?.TenureOptionsDescription,
    }
];

export const FinancialProsperityData = [
    {
        header: texts?.PortfolioDiversification,
        description: texts?.PortfolioDiversificationDescription,
    },
    {
        header: texts?.BigReturnsSmallInvestments,
        description: texts?.BigReturnsSmallInvestmentsDescription,
    },
    {
        header: texts?.PlannedPayouts,
        description: texts?.PlannedPayoutsDescription,
    },
];

export const RegulatedFrameworkData = [
    {
        title: texts?.RegulatorySales,
        description: texts?.RegulatorySalesDescription,
    },
    {
        title: texts?.CreditRating,
        description: texts?.CreditRatingDescription,
        images: [
            {
                altName: 'icra',
                url: '/images/homepage/regulatory-logos/icra.png'
            },
            {
                altName: 'crisil',
                url: '/images/homepage/regulatory-logos/crisil.png'
            },
            {
                altName: 'care-edge',
                url: '/images/homepage/regulatory-logos/care.png'
            },
        ],
    },
    {
        title: texts?.DebentureTrustees,
        description: texts?.DebentureTrusteesDescription,
        images: [
            {
                altName: 'catalyst',
                url: '/images/homepage/regulatory-logos/catalyst.png'
            },
            {
                altName: 'axis-bank',
                url: '/images/homepage/regulatory-logos/axis-bank.png'
            }, {
                altName: 'sbi-cap',
                url: '/images/homepage/regulatory-logos/sbicap.png'
            }, {
                altName: 'vistra-itcl',
                url: '/images/homepage/regulatory-logos/vistra.png'
            },
        ],
    },
];

export const RecentDealsData = [
    {
        isClosed: false,
        companyLogo: '/images/homepage/muthoot.svg',
        companyName: 'Piramal Capital and Housing Finance Limited...',
        industryType: 'Food & Beverages',
        bondType: 'Rural Electrification Bond',
        opportunityType: 'Securitised Debt (SDI)',
        displayIRR: '13.00',
        tenure: '2Y 31D',
        minInvestment: 91000,
        interestPayoutFrequency: 'Monthly',
        principalPayoutFrequency: 'Quarterly',
        riskRatingName: 'CRISIL AA',
        riskCategory: 'Medium',
    },
    {
        isClosed: false,
        companyLogo: '/images/homepage/muthoot.svg',
        companyName: 'Piramal Capital and Housing Finance Limited...',
        industryType: 'Food & Beverages',
        bondType: 'Rural Electrification Bond',
        opportunityType: 'Securitised Debt (SDI)',
        displayIRR: '13.00',
        tenure: '2Y 31D',
        minInvestment: 91000,
        interestPayoutFrequency: 'Monthly',
        principalPayoutFrequency: 'Quarterly',
        riskRatingName: 'CRISIL AA',
        riskCategory: 'Medium',
    },
    {
        isClosed: true,
        companyLogo: '/images/homepage/muthoot.svg',
        companyName: 'Piramal Capital and Housing Finance Limited...',
        industryType: 'Food & Beverages',
        bondType: 'Rural Electrification Bond',
        opportunityType: 'Securitised Debt (SDI)',
        displayIRR: '13.00',
        tenure: '2Y 31D',
        minInvestment: 91000,
        interestPayoutFrequency: 'Monthly',
        principalPayoutFrequency: 'Quarterly',
        riskRatingName: 'CRISIL AA',
        riskCategory: 'Medium',
    }
];